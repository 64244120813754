import clsx from 'clsx';
import Image from 'next/image';

import A3DayBlinds from '/public/images/logos/3 Day Blinds.webp';
import A7Eleven from '/public/images/logos/7-Eleven.webp';
import ANewDay from '/public/images/logos/A New Day.webp';
import AbercrombieFitch from '/public/images/logos/Abercrombie Fitch.webp';
import AcademySports from '/public/images/logos/Academy Sports.webp';
import AceHardware from '/public/images/logos/Ace Hardware.webp';
import AcmeFreshMarket from '/public/images/logos/Acme Fresh Market.webp';
import Adidas from '/public/images/logos/Adidas.webp';
import ADTSecurityServices from '/public/images/logos/ADT Security Services.webp';
import AdvanceAutoParts from '/public/images/logos/Advance Auto Parts.webp';
import AdventureIsland from '/public/images/logos/Adventure Island.webp';
import Aeropostale from '/public/images/logos/Aeropostale.webp';
import Affirm from '/public/images/logos/Affirm.webp';
import Afterpay from '/public/images/logos/Afterpay.webp';
import Airbnb from '/public/images/logos/Airbnb.webp';
import Albertsons from '/public/images/logos/Albertsons.webp';
import ALDI from '/public/images/logos/ALDI.webp';
import Allstate from '/public/images/logos/Allstate.webp';
import AltardState from '/public/images/logos/Altard State.webp';
import AmazonFresh from '/public/images/logos/Amazon Fresh.webp';
import AmazonKindle from '/public/images/logos/Amazon Kindle.webp';
import AmazonMarketplace from '/public/images/logos/Amazon Marketplace.webp';
import AmazonPharmacy from '/public/images/logos/Amazon Pharmacy.webp';
import AmazonPrimeNow from '/public/images/logos/Amazon Prime Now.webp';
import AmazonPrimeVideo from '/public/images/logos/Amazon Prime Video.webp';
import Amazon from '/public/images/logos/Amazon.webp';
import AMCTheatres from '/public/images/logos/AMC Theatres.webp';
import AmericanAirlines from '/public/images/logos/American Airlines.webp';
import AmericanEagle from '/public/images/logos/American Eagle.webp';
import AnnTaylor from '/public/images/logos/Ann Taylor.webp';
import Anthropologie from '/public/images/logos/Anthropologie.webp';
import Apple from '/public/images/logos/Apple.webp';
import Applebees from '/public/images/logos/Applebees.webp';
import Aquatica from '/public/images/logos/Aquatica.webp';
import Arbys from '/public/images/logos/Arbys.webp';
import Athleta from '/public/images/logos/Athleta.webp';
import Audible from '/public/images/logos/Audible.webp';
import AutoZone from '/public/images/logos/AutoZone.webp';
import BadlandsRanch from '/public/images/logos/Badlands Ranch.webp';
import BananaRepublic from '/public/images/logos/Banana Republic.webp';
import BarnesNoble from '/public/images/logos/BarnesNoble.webp';
import BassProShops from '/public/images/logos/Bass Pro Shops.webp';
import BathBodyWorks from '/public/images/logos/BathBodyWorks.webp';
import BeautyCraft from '/public/images/logos/Beauty Craft.webp';
import BeautySystemsGroup from '/public/images/logos/Beauty Systems Group.webp';
import BedBathBeyond from '/public/images/logos/Bed Bath & Beyond.webp';
import Benefit from '/public/images/logos/Benefit.webp';
import Benihana from '/public/images/logos/Benihana.webp';
import BestBuy from '/public/images/logos/BestBuy.webp';
import BetMGM from '/public/images/logos/Bet MGM.webp';
import Bet365 from '/public/images/logos/Bet365.webp';
import BetRivers from '/public/images/logos/BetRivers.webp';
import BevMo from '/public/images/logos/BevMo.webp';
import Big5SportingGoods from '/public/images/logos/Big 5 Sporting Goods.webp';
import BirchBox from '/public/images/logos/BirchBox.webp';
import BJsRestaurants from '/public/images/logos/BJs Restaurants.webp';
import BJsWholesaleClub from '/public/images/logos/BJs Wholesale Club.webp';
import BlazePizza from '/public/images/logos/Blaze Pizza.webp';
import Blinds from '/public/images/logos/Blinds.webp';
import BlindsToGo from '/public/images/logos/BlindsToGo.webp';
import Bloomingdales from '/public/images/logos/Bloomingdales.webp';
import BlueApron from '/public/images/logos/Blue Apron.webp';
import Bluemercury from '/public/images/logos/Bluemercury.webp';
import BMW from '/public/images/logos/BMW.webp';
import BobEvans from '/public/images/logos/Bob Evans.webp';
import BobbiBrown from '/public/images/logos/Bobbi Brown.webp';
import Bonefish from '/public/images/logos/Bonefish.webp';
import Bookingcom from '/public/images/logos/Bookingcom.webp';
import BoxyCharm from '/public/images/logos/BoxyCharm.webp';
import BrandyMelville from '/public/images/logos/Brandy Melville.webp';
import BudgetBlinds from '/public/images/logos/Budget Blinds.webp';
import BudgetRentaCar from '/public/images/logos/Budget Rent a Car.webp';
import BuffaloWildWings from '/public/images/logos/Buffalo Wild Wings.webp';
import BurgerKing from '/public/images/logos/Burger King.webp';
import BurgerFi from '/public/images/logos/BurgerFi.webp';
import Burlington from '/public/images/logos/Burlington.webp';
import BuschGardens from '/public/images/logos/Busch Gardens.webp';
import Cabelas from '/public/images/logos/Cabelas.webp';
import CaesarsSportsbook from '/public/images/logos/Caesars Sportsbook.webp';
import CaliforniaPizzaKitchen from '/public/images/logos/California Pizza Kitchen.webp';
import CarlsJr from '/public/images/logos/Carls Jr.webp';
import Carnival from '/public/images/logos/Carnival.webp';
import Carters from '/public/images/logos/Carters.webp';
import Cava from '/public/images/logos/Cava.webp';
import ChamaGaucha from '/public/images/logos/Chama Gaucha.webp';
import CharleysPhillySteaks from '/public/images/logos/Charleys Philly Steaks.webp';
import Checkers from '/public/images/logos/Checkers.webp';
import CheddarsScratchKitchen from '/public/images/logos/CheddarsScratchKitchen.webp';
import Chewy from '/public/images/logos/Chewy.webp';
import ChickfilA from '/public/images/logos/Chick-fil-A.webp';
import Chilis from '/public/images/logos/Chilis.webp';
import Chima from '/public/images/logos/Chima.webp';
import ChipotleMexicanGrill from '/public/images/logos/Chipotle Mexican Grill.webp';
import CicisPizza from '/public/images/logos/Cicis Pizza.webp';
import Coach from '/public/images/logos/Coach.webp';
import ComfortInn from '/public/images/logos/Comfort Inn.webp';
import Converse from '/public/images/logos/Converse.webp';
import Cookunity from '/public/images/logos/Cookunity.webp';
import CoopersHawk from '/public/images/logos/Coopers Hawk.webp';
import Costco from '/public/images/logos/Costco.webp';
import Covariance from '/public/images/logos/Covariance.webp';
import CrackerBarrel from '/public/images/logos/Cracker Barrel.webp';
import CrateBarrel from '/public/images/logos/Crate  Barrel.webp';
import CrumblCookies from '/public/images/logos/CrumblCookies.webp';
import Culvers from '/public/images/logos/Culvers.webp';
import CVS from '/public/images/logos/CVS.webp';
import DailyHarvest from '/public/images/logos/Daily Harvest.webp';
import DairyQueen from '/public/images/logos/Dairy Queen.webp';
import Dashmart from '/public/images/logos/Dashmart.webp';
import DaveBusters from '/public/images/logos/Dave Busters.webp';
import DelTaco from '/public/images/logos/Del Taco.webp';
import DeltaAirlines from '/public/images/logos/Delta Airlines.webp';
import Dennys from '/public/images/logos/Dennys.webp';
import DicksSportingGoods from '/public/images/logos/Dicks Sporting Goods.webp';
import Dillards from '/public/images/logos/Dillards.webp';
import DiscoveryClothing from '/public/images/logos/Discovery Clothing.webp';
import DiscoveryCove from '/public/images/logos/Discovery Cove.webp';
import DisneyWorldResort from '/public/images/logos/Disney World Resort.webp';
import DisneyAnimalKingdom from '/public/images/logos/DisneyAnimalKingdom.webp';
import DisneyPlus from '/public/images/logos/DisneyPlus.webp';
import DollarGeneral from '/public/images/logos/Dollar General.webp';
import DollarTree from '/public/images/logos/DollarTree.webp';
import DominosPizza from '/public/images/logos/Dominos Pizza.webp';
import DoorDash from '/public/images/logos/DoorDash.webp';
import DraftKings from '/public/images/logos/Draft Kings.webp';
import DSW from '/public/images/logos/DSW.webp';
import DuaneReade from '/public/images/logos/Duane Reade.webp';
import DunhamsSports from '/public/images/logos/Dunhams Sports.webp';
import Dunkin from '/public/images/logos/Dunkin.webp';
import elf from '/public/images/logos/e.l.f..webp';
import EddieVs from '/public/images/logos/Eddie Vs.webp';
import ESPNBet from '/public/images/logos/ESPN Bet.webp';
import ESPNPlus from '/public/images/logos/ESPN Plus.webp';
import ESPN from '/public/images/logos/ESPN.webp';
import EsteeLauder from '/public/images/logos/Estee Lauder.webp';
import EveryPlate from '/public/images/logos/EveryPlate.webp';
import Expedia from '/public/images/logos/Expedia.webp';
import FabFitFun from '/public/images/logos/FabFitFun.webp';
import Factor75 from '/public/images/logos/Factor75.webp';
import FamilyDollar from '/public/images/logos/Family Dollar.webp';
import FamousFootwear from '/public/images/logos/Famous Footwear.webp';
import FanaticsSportsbook from '/public/images/logos/FanaticsSportsbook.webp';
import FanDuel from '/public/images/logos/FanDuel.webp';
import FashionNova from '/public/images/logos/Fashion Nova.webp';
import FentyBeauty from '/public/images/logos/Fenty Beauty.webp';
import FiveGuys from '/public/images/logos/Five Guys.webp';
import Flemings from '/public/images/logos/Flemings.webp';
import FloorDecor from '/public/images/logos/Floor & Decor.webp';
import FogodeChao from '/public/images/logos/Fogo de Chao.webp';
import FoodLion from '/public/images/logos/FoodLion.webp';
import FootLocker from '/public/images/logos/Foot Locker.webp';
import FredMeyer from '/public/images/logos/Fred Meyer.webp';
import Freddys from '/public/images/logos/Freddys.webp';
import FreePeople from '/public/images/logos/Free People.webp';
import Freshpet from '/public/images/logos/Freshpet.webp';
import Fromm from '/public/images/logos/Fromm.webp';
import FunSpot from '/public/images/logos/FunSpot.webp';
import GameStop from '/public/images/logos/GameStop.webp';
import Gap from '/public/images/logos/Gap.webp';
import Geico from '/public/images/logos/Geico.webp';
import GiantFood from '/public/images/logos/Giant Food.webp';
import GiantEagle from '/public/images/logos/GiantEagle.webp';
import Glossier from '/public/images/logos/Glossier.webp';
import GodfathersPizza from '/public/images/logos/Godfathers Pizza.webp';
import GoldenCorral from '/public/images/logos/Golden Corral.webp';
import GolfGalaxy from '/public/images/logos/Golf Galaxy.webp';
import GolfNow from '/public/images/logos/GolfNow.webp';
import GooglePlayStore from '/public/images/logos/GooglePlayStore.webp';
import Gopuff from '/public/images/logos/Gopuff.webp';
import Groupon from '/public/images/logos/Groupon.webp';
import Grubhub from '/public/images/logos/Grubhub.webp';
import HEB from '/public/images/logos/H-E-B.webp';
import HamptonInn from '/public/images/logos/Hampton Inn.webp';
import HannaAndersson from '/public/images/logos/HannaAndersson.webp';
import Hardees from '/public/images/logos/Hardees.webp';
import HarrisTeeter from '/public/images/logos/Harris Teeter.webp';
import Heckova from '/public/images/logos/Heckova.webp';
import HelloFresh from '/public/images/logos/HelloFresh.webp';
import HeyDude from '/public/images/logos/HeyDude.webp';
import Hilton from '/public/images/logos/Hilton.webp';
import HM from '/public/images/logos/HM.webp';
import HobbyLobby from '/public/images/logos/HobbyLobby.webp';
import Hoka from '/public/images/logos/Hoka.webp';
import HollandAmerica from '/public/images/logos/Holland America.webp';
import Hollister from '/public/images/logos/Hollister.webp';
import HomeDepot from '/public/images/logos/HomeDepot.webp';
import HomeGoods from '/public/images/logos/HomeGoods.webp';
import Hooters from '/public/images/logos/Hooters.webp';
import Hotelscom from '/public/images/logos/Hotelscom.webp';
import Hulu from '/public/images/logos/Hulu.webp';
import HungryHowies from '/public/images/logos/Hungry Howies.webp';
import Hyatt from '/public/images/logos/Hyatt.webp';
import IKEA from '/public/images/logos/IKEA.webp';
import InNOut from '/public/images/logos/In-N-Out.webp';
import Instacart from '/public/images/logos/Instacart.webp';
import Ipsy from '/public/images/logos/Ipsy.webp';
import JJill from '/public/images/logos/J Jill.webp';
import JackintheBox from '/public/images/logos/Jack in the Box.webp';
import Jacks from '/public/images/logos/Jacks.webp';
import JCrew from '/public/images/logos/JCrew.webp';
import JetBlue from '/public/images/logos/JetBlue.webp';
import JetsPizza from '/public/images/logos/Jets Pizza.webp';
import JoAnnStores from '/public/images/logos/Jo-Ann Stores.webp';
import JohnstonMurphy from '/public/images/logos/JohnstonMurphy.webp';
import JustFoodForDogs from '/public/images/logos/JustFoodForDogs.webp';
import KAYAK from '/public/images/logos/KAYAK.webp';
import KennedySpaceCenter from '/public/images/logos/KennedySpaceCenter.webp';
import Kohls from '/public/images/logos/Kohls.webp';
import Kroger from '/public/images/logos/Kroger.webp';
import Krystal from '/public/images/logos/Krystal.webp';
import KylieCosmetics from '/public/images/logos/Kylie Cosmetics.webp';
import LAFitness from '/public/images/logos/LA Fitness.webp';
import Lancome from '/public/images/logos/Lancome.webp';
import LEGOLAND from '/public/images/logos/LEGOLAND.webp';
import LittleCaesars from '/public/images/logos/Little Caesars.webp';
import Loft from '/public/images/logos/Loft.webp';
import LonghornSteakhouse from '/public/images/logos/Longhorn Steakhouse.webp';
import LOreal from '/public/images/logos/LOreal.webp';
import LouisVuitton from '/public/images/logos/Louis Vuitton.webp';
import Lowees from '/public/images/logos/Lowees.webp';
import Lululemon from '/public/images/logos/Lululemon.webp';
import Lulus from '/public/images/logos/Lulus.webp';
import Lyft from '/public/images/logos/Lyft.webp';
import MACCosmetics from '/public/images/logos/MAC Cosmetics.webp';
import Macys from '/public/images/logos/Macys.webp';
import MaggianosLittleItaly from '/public/images/logos/Maggianos Little Italy.webp';
import Marathon from '/public/images/logos/Marathon.webp';
import MarcosPizza from '/public/images/logos/Marcos Pizza.webp';
import MargaritavilleResort from '/public/images/logos/Margaritaville Resort.webp';
import MarloBeautySupply from '/public/images/logos/Marlo Beauty Supply.webp';
import MarriottInternational from '/public/images/logos/MarriottInternational.webp';
import Marshalls from '/public/images/logos/Marshalls.webp';
import Max from '/public/images/logos/Max.webp';
import McDonalds from '/public/images/logos/McDonalds.webp';
import MellowMushroom from '/public/images/logos/Mellow Mushroom.webp';
import MeltingPot from '/public/images/logos/Melting Pot.webp';
import MensWearhouse from '/public/images/logos/Mens Wearhouse.webp';
import MercedesBenz from '/public/images/logos/MercedesBenz.webp';
import MerlinEntertainments from '/public/images/logos/MerlinEntertainments.webp';
import Merrell from '/public/images/logos/Merrell.webp';
import MODPizza from '/public/images/logos/MOD Pizza.webp';
import Mortons from '/public/images/logos/Mortons.webp';
import Nars from '/public/images/logos/Nars.webp';
import NeimanMarcus from '/public/images/logos/Neiman Marcus.webp';
import Nespresso from '/public/images/logos/Nespresso.webp';
import Netflix from '/public/images/logos/Netflix.webp';
import Nike from '/public/images/logos/Nike.webp';
import Nintendo from '/public/images/logos/Nintendo.webp';
import NomNom from '/public/images/logos/NomNom.webp';
import Nordstrom from '/public/images/logos/Nordstrom.webp';
import NordstromRack from '/public/images/logos/NordstromRack.webp';
import Norwegian from '/public/images/logos/Norwegian.webp';
import Oceania from '/public/images/logos/Oceania.webp';
import OfficeMax from '/public/images/logos/OfficeMax.webp';
import OldNavy from '/public/images/logos/Old Navy.webp';
import OliveGarden from '/public/images/logos/Olive Garden.webp';
import Ollie from '/public/images/logos/Ollie.webp';
import OnlyFans from '/public/images/logos/OnlyFans.webp';
import Orbitz from '/public/images/logos/Orbitz.webp';
import OutbackSteakhouse from '/public/images/logos/Outback Steakhouse.webp';
import PFChangs from '/public/images/logos/P. F. Changs.webp';
import PandaExpress from '/public/images/logos/Panda Express.webp';
import PaneraBread from '/public/images/logos/Panera Bread.webp';
import PapaJohnsPizza from '/public/images/logos/Papa Johns Pizza.webp';
import PapaMurphys from '/public/images/logos/Papa Murphys.webp';
import ParamountBeauty from '/public/images/logos/Paramount Beauty.webp';
import ParamountPlus from '/public/images/logos/ParamountPlus.webp';
import Patagonia from '/public/images/logos/Patagonia.webp';
import Patreon from '/public/images/logos/Patreon.webp';
import PayPal from '/public/images/logos/PayPal.webp';
import Peacock from '/public/images/logos/Peacock.webp';
import PelotonCycles from '/public/images/logos/Peloton Cycles.webp';
import Perrys from '/public/images/logos/Perrys.webp';
import PetSupermarket from '/public/images/logos/Pet Supermarket.webp';
import PetSuppliesPlus from '/public/images/logos/Pet Supplies Plus.webp';
import Petco from '/public/images/logos/Petco.webp';
import Petflow from '/public/images/logos/Petflow.webp';
import Petland from '/public/images/logos/Petland.webp';
import PetPlate from '/public/images/logos/PetPlate.webp';
import Petsense from '/public/images/logos/Petsense.webp';
import Petsmart from '/public/images/logos/Petsmart.webp';
import PizzaHut from '/public/images/logos/Pizza Hut.webp';
import PizzaRanch from '/public/images/logos/Pizza Ranch.webp';
import Popeyes from '/public/images/logos/Popeyes.webp';
import Postmates from '/public/images/logos/Postmates.webp';
import PotteryBarn from '/public/images/logos/Pottery Barn.webp';
import PremierBeautySupply from '/public/images/logos/Premier Beauty Supply.webp';
import Priceline from '/public/images/logos/Priceline.webp';
import Primark from '/public/images/logos/Primark.webp';
import Princess from '/public/images/logos/Princess.webp';
import Progressive from '/public/images/logos/Progressive.webp';
import Publix from '/public/images/logos/Publix.webp';
import Rallys from '/public/images/logos/Rallys.webp';
import Ralphs from '/public/images/logos/Ralphs.webp';
import RDAPromart from '/public/images/logos/RDA Promart.webp';
import RedLobster from '/public/images/logos/Red Lobster.webp';
import RedRobin from '/public/images/logos/Red Robin.webp';
import RegentSevenSeas from '/public/images/logos/Regent Seven Seas.webp';
import REI from '/public/images/logos/REI.webp';
import RevolveClothing from '/public/images/logos/RevolveClothing.webp';
import RH from '/public/images/logos/RH.webp';
import Ring from '/public/images/logos/Ring.webp';
import RiteAid from '/public/images/logos/Rite Aid.webp';
import RitzCarlton from '/public/images/logos/Ritz Carlton.webp';
import Ross from '/public/images/logos/Ross.webp';
import RoundTable from '/public/images/logos/Round Table.webp';
import RoyalCanin from '/public/images/logos/Royal Canin.webp';
import RoyalCaribbean from '/public/images/logos/Royal Caribbean.webp';
import RubyTuesday from '/public/images/logos/Ruby Tuesday.webp';
import RuthsChrisSteakHouse from '/public/images/logos/Ruths Chris Steak House.webp';
import Safeway from '/public/images/logos/Safeway.webp';
import SaksFifthAvenue from '/public/images/logos/Saks Fifth Avenue.webp';
import SallyBeauty from '/public/images/logos/Sally Beauty.webp';
import SalonServiceGroup from '/public/images/logos/Salon Service Group.webp';
import SalonServicesPro from '/public/images/logos/Salon Services Pro.webp';
import SalonCentric from '/public/images/logos/SalonCentric.webp';
import SamsClub from '/public/images/logos/SamsClub.webp';
import Saucony from '/public/images/logos/Saucony.webp';
import Sbarro from '/public/images/logos/Sbarro.webp';
import SCHEELS from '/public/images/logos/SCHEELS.webp';
import SeatGeek from '/public/images/logos/SeatGeek.webp';
import Seaworld from '/public/images/logos/Seaworld.webp';
import SelectBlinds from '/public/images/logos/SelectBlinds.webp';
import Sephora from '/public/images/logos/Sephora.webp';
import SesamePlace from '/public/images/logos/Sesame Place.webp';
import ShadeStore from '/public/images/logos/Shade Store.webp';
import ShakeShack from '/public/images/logos/Shake Shack.webp';
import Shein from '/public/images/logos/Shein.webp';
import Shell from '/public/images/logos/Shell.webp';
import ShoeCarnival from '/public/images/logos/ShoeCarnival.webp';
import Shoprite from '/public/images/logos/Shoprite.webp';
import SierraTradingPost from '/public/images/logos/Sierra Trading Post.webp';
import Simplisafe from '/public/images/logos/Simplisafe.webp';
import SiriusXM from '/public/images/logos/SiriusXM.webp';
import SixFlags from '/public/images/logos/Six Flags.webp';
import Skechers from '/public/images/logos/Skechers.webp';
import Smashburger from '/public/images/logos/Smashburger.webp';
import SonicDrivein from '/public/images/logos/Sonic Drive-in.webp';
import SonyPlayStationNetwork from '/public/images/logos/SonyPlayStationNetwork.webp';
import Sorel from '/public/images/logos/Sorel.webp';
import SouthwestAirlines from '/public/images/logos/Southwest Airlines.webp';
import Spectrum from '/public/images/logos/Spectrum.webp';
import Speedway from '/public/images/logos/Speedway.webp';
import Sperry from '/public/images/logos/Sperry.webp';
import SpiritAirlines from '/public/images/logos/Spirit Airlines.webp';
import SportsmansWarehouse from '/public/images/logos/Sportsmans Warehouse.webp';
import Spotify from '/public/images/logos/Spotify.webp';
import StRegis from '/public/images/logos/St Regis.webp';
import Staples from '/public/images/logos/Staples.webp';
import Starbucks from '/public/images/logos/Starbucks.webp';
import Starz from '/public/images/logos/Starz.webp';
import StateBeautySupply from '/public/images/logos/State Beauty Supply.webp';
import StateFarm from '/public/images/logos/StateFarm.webp';
import SteaknShake from '/public/images/logos/Steak n Shake.webp';
import Steam from '/public/images/logos/Steam.webp';
import StellaChewy from '/public/images/logos/Stella & Chewy.webp';
import SteveMadden from '/public/images/logos/SteveMadden.webp';
import StrideRite from '/public/images/logos/StrideRite.webp';
import StuartWeitzman from '/public/images/logos/StuartWeitzman.webp';
import StubHub from '/public/images/logos/StubHub.webp';
import Subway from '/public/images/logos/Subway.webp';
import Sullivans from '/public/images/logos/Sullivans.webp';
import SundaysforDogs from '/public/images/logos/Sundays for Dogs.webp';
import Sweetgreen from '/public/images/logos/Sweetgreen.webp';
import Sweis from '/public/images/logos/Sweis.webp';
import TacoBell from '/public/images/logos/Taco Bell.webp';
import Talbots from '/public/images/logos/Talbots.webp';
import Target from '/public/images/logos/Target.webp';
import Tarte from '/public/images/logos/Tarte.webp';
import Tesla from '/public/images/logos/Tesla.webp';
import Teva from '/public/images/logos/Teva.webp';
import TexasdeBrazil from '/public/images/logos/Texas de Brazil.webp';
import TexasRoadhouse from '/public/images/logos/Texas Roadhouse.webp';
import TGIFridays from '/public/images/logos/TGI Fridays.webp';
import TheCapitalGrille from '/public/images/logos/The Capital Grille.webp';
import TheCheesecakeFactory from '/public/images/logos/The Cheesecake Factory.webp';
import TheChildrensPlace from '/public/images/logos/The Childrens Place.webp';
import TheHabit from '/public/images/logos/The Habit.webp';
import TheHomeDepot from '/public/images/logos/The Home Depot.webp';
import TheHonestKitchen from '/public/images/logos/The Honest Kitchen.webp';
import ThePetsTable from '/public/images/logos/The Pets Table.webp';
import TheFarmersDog from '/public/images/logos/TheFarmersDog.webp';
import TheFreshMarket from '/public/images/logos/TheFreshMarket.webp';
import ThriveMarket from '/public/images/logos/Thrive Market.webp';
import Ticketmaster from '/public/images/logos/Ticketmaster.webp';
import TiffanyCo from '/public/images/logos/TiffanyCo.webp';
import TJMaxx from '/public/images/logos/TJ Maxx.webp';
import TooFaced from '/public/images/logos/Too Faced.webp';
import Topgolf from '/public/images/logos/Topgolf.webp';
import TotalWineMore from '/public/images/logos/Total Wine  More.webp';
import Toyota from '/public/images/logos/Toyota.webp';
import TractorSupply from '/public/images/logos/Tractor Supply.webp';
import TraderJoes from '/public/images/logos/TraderJoes.webp';
import TravelersInsurance from '/public/images/logos/Travelers Insurance.webp';
import Travelocity from '/public/images/logos/Travelocity.webp';
import trivago from '/public/images/logos/trivago.webp';
import TrustarSalonServices from '/public/images/logos/Trustar Salon Services.webp';
import TwinStateSalonSupply from '/public/images/logos/Twin State Salon Supply.webp';
import Uber from '/public/images/logos/Uber.webp';
import UberEats from '/public/images/logos/UberEats.webp';
import UltaBeauty from '/public/images/logos/Ulta Beauty.webp';
import Uniqlo from '/public/images/logos/Uniqlo.webp';
import UnitedAirlines from '/public/images/logos/UnitedAirlines.webp';
import UniversalIslandsofAdventure from '/public/images/logos/UniversalIslandsofAdventure.webp';
import UniversalStudiosOrlando from '/public/images/logos/UniversalStudiosOrlando.webp';
import UniversalVolcanoBay from '/public/images/logos/UniversalVolcanoBay.webp';
import UrbanDecay from '/public/images/logos/Urban Decay.webp';
import UrbanOutfitters from '/public/images/logos/Urban Outfitters.webp';
import VailResorts from '/public/images/logos/Vail Resorts.webp';
import Venmo from '/public/images/logos/Venmo.webp';
import VictoriasSecret from '/public/images/logos/Victorias Secret.webp';
import Vons from '/public/images/logos/Vons.webp';
import Vrbo from '/public/images/logos/Vrbo.webp';
import W from '/public/images/logos/W.webp';
import WaffleHouse from '/public/images/logos/Waffle House.webp';
import Walgreens from '/public/images/logos/Walgreens.webp';
import Walmart from '/public/images/logos/Walmart.webp';
import WaterCountryUSA from '/public/images/logos/Water Country USA.webp';
import Wayfair from '/public/images/logos/Wayfair.webp';
import Wegmans from '/public/images/logos/Wegmans.webp';
import Wella from '/public/images/logos/Wella.webp';
import Wendys from '/public/images/logos/Wendys.webp';
import WestinHotelsResorts from '/public/images/logos/Westin Hotels Resorts.webp';
import Whataburger from '/public/images/logos/Whataburger.webp';
import WhiteCastle from '/public/images/logos/White Castle.webp';
import WholeFoods from '/public/images/logos/Whole Foods.webp';
import Wienerschnitzel from '/public/images/logos/Wienerschnitzel.webp';
import WilliamsSonoma from '/public/images/logos/Williams Sonoma.webp';
import Xbox from '/public/images/logos/Xbox.webp';
import YardHouse from '/public/images/logos/Yard House.webp';
import YMCA from '/public/images/logos/YMCA.webp';
import YouTube from '/public/images/logos/YouTube.webp';
import Zappos from '/public/images/logos/Zappos.webp';
import Zara from '/public/images/logos/Zara.webp';
import { Styled } from '@/src/app/helpers/types';

const logoPaths = {
  '3DayBlinds': A3DayBlinds,
  '7Eleven': A7Eleven,
  ANewDay: ANewDay,
  AbercrombieFitch: AbercrombieFitch,
  AcademySports: AcademySports,
  AceHardware: AceHardware,
  AcmeFreshMarket: AcmeFreshMarket,
  Adidas: Adidas,
  ADTSecurityServices: ADTSecurityServices,
  AdvanceAutoParts: AdvanceAutoParts,
  AdventureIsland: AdventureIsland,
  Aeropostale: Aeropostale,
  Affirm: Affirm,
  Afterpay: Afterpay,
  Airbnb: Airbnb,
  Albertsons: Albertsons,
  ALDI: ALDI,
  Allstate: Allstate,
  AltardState: AltardState,
  AmazonFresh: AmazonFresh,
  AmazonKindle: AmazonKindle,
  AmazonMarketplace: AmazonMarketplace,
  AmazonPharmacy: AmazonPharmacy,
  AmazonPrimeNow: AmazonPrimeNow,
  AmazonPrimeVideo: AmazonPrimeVideo,
  Amazon: Amazon,
  AMCTheatres: AMCTheatres,
  AmericanAirlines: AmericanAirlines,
  AmericanEagle: AmericanEagle,
  AnnTaylor: AnnTaylor,
  Anthropologie: Anthropologie,
  Apple: Apple,
  Applebees: Applebees,
  Aquatica: Aquatica,
  Arbys: Arbys,
  Athleta: Athleta,
  Audible: Audible,
  AutoZone: AutoZone,
  BadlandsRanch: BadlandsRanch,
  BananaRepublic: BananaRepublic,
  BarnesNoble: BarnesNoble,
  BassProShops: BassProShops,
  BathBodyWorks: BathBodyWorks,
  BeautyCraft: BeautyCraft,
  BeautySystemsGroup: BeautySystemsGroup,
  BedBathBeyond: BedBathBeyond,
  Benefit: Benefit,
  Benihana: Benihana,
  BestBuy: BestBuy,
  BetMGM: BetMGM,
  Bet365: Bet365,
  BetRivers: BetRivers,
  BevMo: BevMo,
  Big5SportingGoods: Big5SportingGoods,
  BirchBox: BirchBox,
  BJsRestaurants: BJsRestaurants,
  BJsWholesaleClub: BJsWholesaleClub,
  BlazePizza: BlazePizza,
  Blinds: Blinds,
  BlindsToGo: BlindsToGo,
  Bloomingdales: Bloomingdales,
  BlueApron: BlueApron,
  Bluemercury: Bluemercury,
  BMW: BMW,
  BobEvans: BobEvans,
  BobbiBrown: BobbiBrown,
  Bonefish: Bonefish,
  Bookingcom: Bookingcom,
  BoxyCharm: BoxyCharm,
  BrandyMelville: BrandyMelville,
  BudgetBlinds: BudgetBlinds,
  BudgetRentaCar: BudgetRentaCar,
  BuffaloWildWings: BuffaloWildWings,
  BurgerKing: BurgerKing,
  BurgerFi: BurgerFi,
  Burlington: Burlington,
  BuschGardens: BuschGardens,
  Cabelas: Cabelas,
  CaesarsSportsbook: CaesarsSportsbook,
  CaliforniaPizzaKitchen: CaliforniaPizzaKitchen,
  CarlsJr: CarlsJr,
  Carnival: Carnival,
  Carters: Carters,
  Cava: Cava,
  ChamaGaucha: ChamaGaucha,
  CharleysPhillySteaks: CharleysPhillySteaks,
  Checkers: Checkers,
  CheddarsScratchKitchen: CheddarsScratchKitchen,
  Chewy: Chewy,
  ChickfilA: ChickfilA,
  Chilis: Chilis,
  Chima: Chima,
  ChipotleMexicanGrill: ChipotleMexicanGrill,
  CicisPizza: CicisPizza,
  Coach: Coach,
  ComfortInn: ComfortInn,
  Converse: Converse,
  Cookunity: Cookunity,
  CoopersHawk: CoopersHawk,
  Costco: Costco,
  Covariance: Covariance,
  CrackerBarrel: CrackerBarrel,
  CrateBarrel: CrateBarrel,
  CrumblCookies: CrumblCookies,
  Culvers: Culvers,
  CVS: CVS,
  DailyHarvest: DailyHarvest,
  DairyQueen: DairyQueen,
  Dashmart: Dashmart,
  DaveBusters: DaveBusters,
  DelTaco: DelTaco,
  DeltaAirlines: DeltaAirlines,
  Dennys: Dennys,
  DicksSportingGoods: DicksSportingGoods,
  Dillards: Dillards,
  DiscoveryClothing: DiscoveryClothing,
  DiscoveryCove: DiscoveryCove,
  DisneyWorldResort: DisneyWorldResort,
  DisneyAnimalKingdom: DisneyAnimalKingdom,
  DisneyPlus: DisneyPlus,
  DollarGeneral: DollarGeneral,
  DollarTree: DollarTree,
  DominosPizza: DominosPizza,
  DoorDash: DoorDash,
  DraftKings: DraftKings,
  DSW: DSW,
  DuaneReade: DuaneReade,
  DunhamsSports: DunhamsSports,
  Dunkin: Dunkin,
  elf: elf,
  EddieVs: EddieVs,
  ESPNBet: ESPNBet,
  ESPNPlus: ESPNPlus,
  ESPN: ESPN,
  EsteeLauder: EsteeLauder,
  EveryPlate: EveryPlate,
  Expedia: Expedia,
  FabFitFun: FabFitFun,
  Factor75: Factor75,
  FamilyDollar: FamilyDollar,
  FamousFootwear: FamousFootwear,
  FanaticsSportsbook: FanaticsSportsbook,
  FanDuel: FanDuel,
  FashionNova: FashionNova,
  FentyBeauty: FentyBeauty,
  FiveGuys: FiveGuys,
  Flemings: Flemings,
  FloorDecor: FloorDecor,
  FogodeChao: FogodeChao,
  FoodLion: FoodLion,
  FootLocker: FootLocker,
  FredMeyer: FredMeyer,
  Freddys: Freddys,
  FreePeople: FreePeople,
  Freshpet: Freshpet,
  Fromm: Fromm,
  FunSpot: FunSpot,
  GameStop: GameStop,
  Gap: Gap,
  Geico: Geico,
  GiantFood: GiantFood,
  GiantEagle: GiantEagle,
  Glossier: Glossier,
  GodfathersPizza: GodfathersPizza,
  GoldenCorral: GoldenCorral,
  GolfGalaxy: GolfGalaxy,
  GolfNow: GolfNow,
  GooglePlayStore: GooglePlayStore,
  Gopuff: Gopuff,
  Groupon: Groupon,
  Grubhub: Grubhub,
  HEB: HEB,
  HamptonInn: HamptonInn,
  HannaAndersson: HannaAndersson,
  Hardees: Hardees,
  HarrisTeeter: HarrisTeeter,
  Heckova: Heckova,
  HelloFresh: HelloFresh,
  HeyDude: HeyDude,
  Hilton: Hilton,
  HM: HM,
  HobbyLobby: HobbyLobby,
  Hoka: Hoka,
  HollandAmerica: HollandAmerica,
  Hollister: Hollister,
  HomeDepot: HomeDepot,
  HomeGoods: HomeGoods,
  Hooters: Hooters,
  Hotelscom: Hotelscom,
  Hulu: Hulu,
  HungryHowies: HungryHowies,
  Hyatt: Hyatt,
  IKEA: IKEA,
  InNOut: InNOut,
  Instacart: Instacart,
  Ipsy: Ipsy,
  JJill: JJill,
  JackintheBox: JackintheBox,
  Jacks: Jacks,
  JCrew: JCrew,
  JetBlue: JetBlue,
  JetsPizza: JetsPizza,
  JoAnnStores: JoAnnStores,
  JohnstonMurphy: JohnstonMurphy,
  JustFoodForDogs: JustFoodForDogs,
  KAYAK: KAYAK,
  KennedySpaceCenter: KennedySpaceCenter,
  Kohls: Kohls,
  Kroger: Kroger,
  Krystal: Krystal,
  KylieCosmetics: KylieCosmetics,
  LAFitness: LAFitness,
  Lancome: Lancome,
  LEGOLAND: LEGOLAND,
  LittleCaesars: LittleCaesars,
  Loft: Loft,
  LonghornSteakhouse: LonghornSteakhouse,
  LOreal: LOreal,
  LouisVuitton: LouisVuitton,
  Lowees: Lowees,
  Lululemon: Lululemon,
  Lulus: Lulus,
  Lyft: Lyft,
  MACCosmetics: MACCosmetics,
  Macys: Macys,
  MaggianosLittleItaly: MaggianosLittleItaly,
  Marathon: Marathon,
  MarcosPizza: MarcosPizza,
  MargaritavilleResort: MargaritavilleResort,
  MarloBeautySupply: MarloBeautySupply,
  MarriottInternational: MarriottInternational,
  Marshalls: Marshalls,
  Max: Max,
  McDonalds: McDonalds,
  MellowMushroom: MellowMushroom,
  MeltingPot: MeltingPot,
  MensWearhouse: MensWearhouse,
  MercedesBenz: MercedesBenz,
  MerlinEntertainments: MerlinEntertainments,
  Merrell: Merrell,
  MODPizza: MODPizza,
  Mortons: Mortons,
  Nars: Nars,
  NeimanMarcus: NeimanMarcus,
  Nespresso: Nespresso,
  Netflix: Netflix,
  Nike: Nike,
  Nintendo: Nintendo,
  NomNom: NomNom,
  Nordstrom: Nordstrom,
  NordstromRack: NordstromRack,
  Norwegian: Norwegian,
  Oceania: Oceania,
  OfficeMax: OfficeMax,
  OldNavy: OldNavy,
  OliveGarden: OliveGarden,
  Ollie: Ollie,
  OnlyFans: OnlyFans,
  Orbitz: Orbitz,
  OutbackSteakhouse: OutbackSteakhouse,
  PFChangs: PFChangs,
  PandaExpress: PandaExpress,
  PaneraBread: PaneraBread,
  PapaJohnsPizza: PapaJohnsPizza,
  PapaMurphys: PapaMurphys,
  ParamountBeauty: ParamountBeauty,
  ParamountPlus: ParamountPlus,
  Patagonia: Patagonia,
  Patreon: Patreon,
  PayPal: PayPal,
  Peacock: Peacock,
  PelotonCycles: PelotonCycles,
  Perrys: Perrys,
  PetSupermarket: PetSupermarket,
  PetSuppliesPlus: PetSuppliesPlus,
  Petco: Petco,
  Petflow: Petflow,
  Petland: Petland,
  PetPlate: PetPlate,
  Petsense: Petsense,
  Petsmart: Petsmart,
  PizzaHut: PizzaHut,
  PizzaRanch: PizzaRanch,
  Popeyes: Popeyes,
  Postmates: Postmates,
  PotteryBarn: PotteryBarn,
  PremierBeautySupply: PremierBeautySupply,
  Priceline: Priceline,
  Primark: Primark,
  Princess: Princess,
  Progressive: Progressive,
  Publix: Publix,
  Rallys: Rallys,
  Ralphs: Ralphs,
  RDAPromart: RDAPromart,
  RedLobster: RedLobster,
  RedRobin: RedRobin,
  RegentSevenSeas: RegentSevenSeas,
  REI: REI,
  RevolveClothing: RevolveClothing,
  RH: RH,
  Ring: Ring,
  RiteAid: RiteAid,
  RitzCarlton: RitzCarlton,
  Ross: Ross,
  RoundTable: RoundTable,
  RoyalCanin: RoyalCanin,
  RoyalCaribbean: RoyalCaribbean,
  RubyTuesday: RubyTuesday,
  RuthsChrisSteakHouse: RuthsChrisSteakHouse,
  Safeway: Safeway,
  SaksFifthAvenue: SaksFifthAvenue,
  SallyBeauty: SallyBeauty,
  SalonServiceGroup: SalonServiceGroup,
  SalonServicesPro: SalonServicesPro,
  SalonCentric: SalonCentric,
  SamsClub: SamsClub,
  Saucony: Saucony,
  Sbarro: Sbarro,
  SCHEELS: SCHEELS,
  SeatGeek: SeatGeek,
  Seaworld: Seaworld,
  SelectBlinds: SelectBlinds,
  Sephora: Sephora,
  SesamePlace: SesamePlace,
  ShadeStore: ShadeStore,
  ShakeShack: ShakeShack,
  Shein: Shein,
  Shell: Shell,
  ShoeCarnival: ShoeCarnival,
  Shoprite: Shoprite,
  SierraTradingPost: SierraTradingPost,
  Simplisafe: Simplisafe,
  SiriusXM: SiriusXM,
  SixFlags: SixFlags,
  Skechers: Skechers,
  Smashburger: Smashburger,
  SonicDrivein: SonicDrivein,
  SonyPlayStationNetwork: SonyPlayStationNetwork,
  Sorel: Sorel,
  SouthwestAirlines: SouthwestAirlines,
  Spectrum: Spectrum,
  Speedway: Speedway,
  Sperry: Sperry,
  SpiritAirlines: SpiritAirlines,
  SportsmansWarehouse: SportsmansWarehouse,
  Spotify: Spotify,
  StRegis: StRegis,
  Staples: Staples,
  Starbucks: Starbucks,
  Starz: Starz,
  StateBeautySupply: StateBeautySupply,
  StateFarm: StateFarm,
  SteaknShake: SteaknShake,
  Steam: Steam,
  StellaChewy: StellaChewy,
  SteveMadden: SteveMadden,
  StrideRite: StrideRite,
  StuartWeitzman: StuartWeitzman,
  StubHub: StubHub,
  Subway: Subway,
  Sullivans: Sullivans,
  SundaysforDogs: SundaysforDogs,
  Sweetgreen: Sweetgreen,
  Sweis: Sweis,
  TacoBell: TacoBell,
  Talbots: Talbots,
  Target: Target,
  Tarte: Tarte,
  Tesla: Tesla,
  Teva: Teva,
  TexasdeBrazil: TexasdeBrazil,
  TexasRoadhouse: TexasRoadhouse,
  TGIFridays: TGIFridays,
  TheCapitalGrille: TheCapitalGrille,
  TheCheesecakeFactory: TheCheesecakeFactory,
  TheChildrensPlace: TheChildrensPlace,
  TheHabit: TheHabit,
  TheHomeDepot: TheHomeDepot,
  TheHonestKitchen: TheHonestKitchen,
  ThePetsTable: ThePetsTable,
  TheFarmersDog: TheFarmersDog,
  TheFreshMarket: TheFreshMarket,
  ThriveMarket: ThriveMarket,
  Ticketmaster: Ticketmaster,
  TiffanyCo: TiffanyCo,
  TJMaxx: TJMaxx,
  TooFaced: TooFaced,
  Topgolf: Topgolf,
  TotalWineMore: TotalWineMore,
  Toyota: Toyota,
  TractorSupply: TractorSupply,
  TraderJoes: TraderJoes,
  TravelersInsurance: TravelersInsurance,
  Travelocity: Travelocity,
  trivago: trivago,
  TrustarSalonServices: TrustarSalonServices,
  TwinStateSalonSupply: TwinStateSalonSupply,
  Uber: Uber,
  UberEats: UberEats,
  UltaBeauty: UltaBeauty,
  Uniqlo: Uniqlo,
  UnitedAirlines: UnitedAirlines,
  UniversalIslandsofAdventure: UniversalIslandsofAdventure,
  UniversalStudiosOrlando: UniversalStudiosOrlando,
  UniversalVolcanoBay: UniversalVolcanoBay,
  UrbanDecay: UrbanDecay,
  UrbanOutfitters: UrbanOutfitters,
  VailResorts: VailResorts,
  Venmo: Venmo,
  VictoriasSecret: VictoriasSecret,
  Vons: Vons,
  Vrbo: Vrbo,
  W: W,
  WaffleHouse: WaffleHouse,
  Walgreens: Walgreens,
  Walmart: Walmart,
  WaterCountryUSA: WaterCountryUSA,
  Wayfair: Wayfair,
  Wegmans: Wegmans,
  Wella: Wella,
  Wendys: Wendys,
  WestinHotelsResorts: WestinHotelsResorts,
  Whataburger: Whataburger,
  WhiteCastle: WhiteCastle,
  WholeFoods: WholeFoods,
  Wienerschnitzel: Wienerschnitzel,
  WilliamsSonoma: WilliamsSonoma,
  Xbox: Xbox,
  YardHouse: YardHouse,
  YMCA: YMCA,
  YouTube: YouTube,
  Zappos: Zappos,
  Zara: Zara,
};

type BrandName = keyof typeof logoPaths;

export function isSupportedBrandName(
  brandName: string,
): brandName is BrandName {
  return logoPaths.hasOwnProperty(brandName);
}

type BrandLogoProps = Styled<{
  brand: { color: string; imageName: string | null };
  className?: string;
  zIndex?: number;
  size?: number;
  tabIndex?: number;
}>;

export const BrandLogo = ({
  brand,
  className,
  zIndex,
  size = 6,
  tabIndex,
  style,
}: BrandLogoProps) => {
  const { imageName } = brand;

  const logoAvailable = imageName && isSupportedBrandName(imageName);
  const sizeClassName = `h-${size} w-${size}`;

  if (!logoAvailable) {
    return (
      <div
        className={clsx('rounded-full', sizeClassName, className)}
        style={{ ...style, backgroundColor: brand.color, zIndex }}
        tabIndex={tabIndex}
      />
    );
  }

  return (
    <Image
      src={logoPaths[imageName]}
      alt=""
      className={clsx('rounded-full', sizeClassName, className)}
      style={{ ...style, zIndex }}
      tabIndex={tabIndex}
    />
  );
};
